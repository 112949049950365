<template>
  <div>
    <Basic-Container>
      <avue-crud
        class="avueCrud"
        ref="crud"
        v-model="crudModel"
        :page.sync="page"
        :data="tableData"
        @row-save="rowSave"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @search-change="searchChange"
        @search-reset="searchReset"
        @size-change="sizeChange"
        @current-change="currentChange"
        @refresh-change="refreshChange"
        :before-open="beforeOpen"
        :table-loading="tableLoading"
        :option="tableOption"
      >
        <!-- 排序 -->
        <template v-slot:sequence="{ row }">
          <div class="sort-css">
            <div>{{ row.sequence }}</div>
            <div
              class="sort-icon"
              v-if="row.sequence !== 1"
              @click="sortClick('top', row)"
            >
              <el-button
                class="icon-btn"
                type="primary"
                icon="el-icon-top"
              ></el-button>
            </div>
            <div class="sort-icon" @click="sortClick('bottom', row)">
              <el-button
                class="icon-btn"
                type="warning"
                icon="el-icon-bottom"
              ></el-button>
            </div>
          </div>
        </template>
        <!-- 可见地区 -->
        <template v-slot:cityCodes="{ row }">
          <div v-for="city of row.cityCodes" :key="city">
            {{ getName(city, 1) }}
          </div>
        </template>
        <!-- 是否隐藏 -->
        <template v-slot:published="{ row }">
          <el-switch
            v-model="row.published"
            active-text="显示"
            inactive-text="隐藏"
            :active-value="1"
            :inactive-value="0"
            @change="changeRowPub(row, row.published)"
          >
          </el-switch>
        </template>
        <!-- 是否推荐 -->
        <template v-slot:recommended="{ row }">
          <el-switch
            v-model="row.recommended"
            active-text="热门推荐"
            inactive-text="取消推荐"
            :active-value="1"
            :inactive-value="0"
            @change="changeRowRec(row, row.recommended)"
          >
          </el-switch>
        </template>
        <template v-slot:liulanliang="{ row }">
          <div>
            <p>实际：{{ row.realReadAmount || 0 }}</p>
            <p>基础：{{ row.baseReadAmount || 0 }}</p>
            <p>
              展示：{{
                Number(row.baseReadAmount || 0) +
                Number(row.realReadAmount || 0)
              }}
            </p>
          </div>
        </template>
        <template slot-scope="{ row }" slot="coverImages">
          <div
            v-if="row.coverImages && row.coverImages != ''"
            v-viewer="{ movable: false, url: 'data-src' }"
          >
            <template v-for="image in row.coverImages">
              <img
                style="
                  cursor: pointer;
                  width: 56px;
                  height: 56px;
                  object-fit: contain;
                "
                :src="image + '?x-oss-process=image/resize,m_fixed,h_100,w_100'"
                :data-src="image"
                class="image"
                :key="image"
              />
            </template>
          </div>
          <div v-else>
            <span>暂无推荐墓型</span>
          </div>
        </template>
        <template slot="coverImagesForm">
          <UploadBtn tips="最多上传1张" v-model="crudModel.coverImages" />
        </template>
        <template slot="contentForm">
          <ZEditor v-model="crudModel.content" />
        </template>
        <template slot="cityCodesForm">
          <el-select
            ref="cityCodeRef"
            filterable
            clearable
            multiple
            v-model="crudModel.cityCodes"
            placeholder="请选择"
            @change="changeCityCode"
          >
            <el-option
              v-for="item in citys"
              :key="item.code"
              :label="item.name"
              :value="item.code"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
        </template>
      </avue-crud>
    </Basic-Container>
  </div>
</template>
<script>
import { tableOption } from "./tableOption";
import {
  getInfoListApi,
  postEditApi,
  postUpdateRankApi,
  posUpdatePublishApi,
  posUpdateRecApi,
} from "@/api/xmwmanage/info.js";
import { citys, getName } from "@/util/location/index.js";
export default {
  name: "info",
  filters: {},
  components: {
    UploadBtn: () => import("@/components/uploadList/index.vue"),
    ZEditor: () => import("@/components/editor/index.vue"),
  },
  watch: {},
  data() {
    return {
      crudModel: {},
      searchForm: {},
      tableLoading: false,
      tableData: [],
      tableOption: tableOption,
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50, 100],
      },
      citys: JSON.parse(JSON.stringify(citys)),
      props: {
        value: "code",
        label: "name",
        multiple: true,
      },
      disabledCity: false,
      categoryList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "行业动态",
          value: "行业动态",
        },
        {
          label: "陵园解答",
          value: "陵园解答",
        },
        {
          label: "选墓攻略",
          value: "选墓攻略",
        },
        {
          label: "殡葬常识",
          value: "殡葬常识",
        },
        {
          label: "文化习俗",
          value: "文化习俗",
        },
      ],
    };
  },
  created() {
    this.citys.unshift({
      code: "-1",
      name: "全国",
      provinceCode: "-2",
    });
    this.getList(this.page);
  },
  computed: {},
  // beforeDestroy() {
  //   const editor = this.editor;
  //   if (editor == null) return;
  //   editor.destroy(); // 组件销毁时，及时销毁编辑器
  // },
  methods: {
    getList(page, params) {
      this.tableLoading = true;
      getInfoListApi(
        Object.assign(
          {
            current: page.currentPage,
            size: page.pageSize,
          },
          params,
          this.searchForm
        )
      )
        .then((response) => {
          let data = response.data.data;
          this.tableData = data.records || [];
          this.page.total = Number(data.total) || 0;
          this.tableLoading = false;
        })
        .catch(() => {
          this.$message.error("查询失败");
          this.tableLoading = false;
        });
    },
    searchChange(form, done) {
      this.searchForm = form;
      this.page.currentPage = 1;
      this.getList(this.page, form);
      done();
    },
    sizeChange(pageSize) {
      this.page.currentPage = 1;
      this.page.pageSize = pageSize;
      this.getList(this.page);
    },
    currentChange(current) {
      this.page.currentPage = current;
      this.getList(this.page);
    },
    searchReset() {},
    rowSave(form, done, loading) {
      loading();
      let params = JSON.parse(JSON.stringify(form));
      let newParams = {
        baseReadAmount: params.baseReadAmount,
        categorys: params.categorys,
        cityCodes: params.cityCodes,
        coverImages: params.coverImages,
        title: params.title,
        content: params.content,
        recommended: 0,
        published: 0,
      };
      postEditApi(newParams)
        .then((res) => {
          this.getList(this.page);
          this.$message.success("新增成功");
          done();
        })
        .catch((err) => {
          this.$message.error("新增失败");
        });
    },
    rowUpdate(form, index, done, loading) {
      let params = JSON.parse(JSON.stringify(form));
      console.log(params,'params');
      delete params.liulanliang
      postEditApi(params)
        .then((res) => {
          this.$message.success("修改成功");
          this.getList(this.page);
          done();
        })
        .catch((err) => {
          loading();
          this.$message.warning("网络错误,请稍候重试");
        });
    },
    refreshChange() {
      this.getList(this.page);
    },
    beforeOpen(done, type) {
      if (type === "add") {
        this.$nextTick(() => {
          setTimeout(() => {
            this.crudModel.recommendImage = [];
          }, 0);
        });
      } else {
        // let row = this.crudModel;
        // this.crudModel.recommendImage = row.recommendImage
        //   ? [row.recommendImage]
        //   : [];
        // this.crudModel.cemeteryIds = row.recommendCemetery.map((item) => {
        //   return item.id;
        // });
        // this.$set(this.crudModel, "location", [row.provinceCode, row.cityCode]);
      }
      done();
    },
    // 删除
    rowDel(form, index) {
      console.log(form, "form");
      this.$confirm(`确定要删除${form.cityName}的首页推荐吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteApi(form.id)
            .then((res) => {
              this.$message.success("删除成功");
              this.getList(this.page);
            })
            .catch((err) => {
              console.log(err, "错误");
              this.$message.warning("网络错误,请稍候重试");
            });
        })
        .catch(() => {});
    },
    changeCityCode(row) {
      let newcitys = JSON.parse(JSON.stringify(this.citys));
      if (row.includes("-1")) {
        for (let i = 0; i < newcitys.length; i++) {
          const item = newcitys[i];
          if (item.code !== "-1") {
            item.disabled = true;
          }
        }
        this.$set(this.crudModel, "cityCodes", ["-1"]);
      } else {
        this.disabledCity = false;
        for (let i = 0; i < newcitys.length; i++) {
          const item = newcitys[i];
          item.disabled = false;
        }
      }
      this.citys = newcitys;
      console.log(this.citys, "llll");
      setTimeout(() => {
        console.log(row, "ddddd");
      }, 100);
    },
    getName(code, index) {
      if (code == "-1") {
        return "全国";
      }
      return getName(code, index);
    },
   async changeRowRec(row, status) {
        const res = this.posUpdateRec(row);
      if(res) {
        this.$message.success('切换推荐状态成功')
      }else {
         this.$message.error('切换推荐状态失败')
      }
      setTimeout(()=> {
         this.getList(this.page)
      }, 1000)
    },
    async changeRowPub(row, status) {
      const res = this.posUpdatePublish(row);
      if(res) {
        this.$message.success('切换显示状态成功')
      }else {
         this.$message.error('切换显示状态失败')
      }
      setTimeout(()=> {
        this.getList(this.page)
      }, 1000)
    },
    sortClick(type, row) {
      let str = type === "top" ? "提升" : "降低";
      let id = row.id;
      this.$confirm(`确定${str} ${row.title} 的排序吗 ?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let operateType = type === "top" ? 1 : 2;
          postUpdateRankApi({
            id,
            operateType,
          }).then((res) => {
            this.$message.success("操作成功!");
            this.getList(this.page);
          });
        })
        .catch(() => {})
        .finally(() => {});
    },
    // 切换隐藏 显示
    posUpdatePublish(row) {
      let published = row.published
      let id = row.id;
      let params = { published, id };
      return new Promise((resolve) => {
        posUpdatePublishApi(params)
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    //取消推荐-热门推荐
    posUpdateRec(row) {
      let recommended = row.recommended 
      let id = row.id;
      let params = { recommended, id };
      return new Promise((resolve) => {
        posUpdateRecApi(params)
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.sort-css /deep/ {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  .sort-icon {
    margin: 0 4px;
  }
  .icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    padding: 2px 4px;
    font-size: 18px;
  }
}
</style>