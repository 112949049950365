var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Basic-Container',[_c('avue-crud',{ref:"crud",staticClass:"avueCrud",attrs:{"page":_vm.page,"data":_vm.tableData,"before-open":_vm.beforeOpen,"table-loading":_vm.tableLoading,"option":_vm.tableOption},on:{"update:page":function($event){_vm.page=$event},"row-save":_vm.rowSave,"row-del":_vm.rowDel,"row-update":_vm.rowUpdate,"search-change":_vm.searchChange,"search-reset":_vm.searchReset,"size-change":_vm.sizeChange,"current-change":_vm.currentChange,"refresh-change":_vm.refreshChange},scopedSlots:_vm._u([{key:"sequence",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"sort-css"},[_c('div',[_vm._v(_vm._s(row.sequence))]),(row.sequence !== 1)?_c('div',{staticClass:"sort-icon",on:{"click":function($event){return _vm.sortClick('top', row)}}},[_c('el-button',{staticClass:"icon-btn",attrs:{"type":"primary","icon":"el-icon-top"}})],1):_vm._e(),_c('div',{staticClass:"sort-icon",on:{"click":function($event){return _vm.sortClick('bottom', row)}}},[_c('el-button',{staticClass:"icon-btn",attrs:{"type":"warning","icon":"el-icon-bottom"}})],1)])]}},{key:"cityCodes",fn:function(ref){
var row = ref.row;
return _vm._l((row.cityCodes),function(city){return _c('div',{key:city},[_vm._v(" "+_vm._s(_vm.getName(city, 1))+" ")])})}},{key:"published",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-text":"显示","inactive-text":"隐藏","active-value":1,"inactive-value":0},on:{"change":function($event){return _vm.changeRowPub(row, row.published)}},model:{value:(row.published),callback:function ($$v) {_vm.$set(row, "published", $$v)},expression:"row.published"}})]}},{key:"recommended",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-text":"热门推荐","inactive-text":"取消推荐","active-value":1,"inactive-value":0},on:{"change":function($event){return _vm.changeRowRec(row, row.recommended)}},model:{value:(row.recommended),callback:function ($$v) {_vm.$set(row, "recommended", $$v)},expression:"row.recommended"}})]}},{key:"liulanliang",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',[_vm._v("实际："+_vm._s(row.realReadAmount || 0))]),_c('p',[_vm._v("基础："+_vm._s(row.baseReadAmount || 0))]),_c('p',[_vm._v(" 展示："+_vm._s(Number(row.baseReadAmount || 0) + Number(row.realReadAmount || 0))+" ")])])]}},{key:"coverImages",fn:function(ref){
var row = ref.row;
return [(row.coverImages && row.coverImages != '')?_c('div',{directives:[{name:"viewer",rawName:"v-viewer",value:({ movable: false, url: 'data-src' }),expression:"{ movable: false, url: 'data-src' }"}]},[_vm._l((row.coverImages),function(image){return [_c('img',{key:image,staticClass:"image",staticStyle:{"cursor":"pointer","width":"56px","height":"56px","object-fit":"contain"},attrs:{"src":image + '?x-oss-process=image/resize,m_fixed,h_100,w_100',"data-src":image}})]})],2):_c('div',[_c('span',[_vm._v("暂无推荐墓型")])])]}}]),model:{value:(_vm.crudModel),callback:function ($$v) {_vm.crudModel=$$v},expression:"crudModel"}},[_c('template',{slot:"coverImagesForm"},[_c('UploadBtn',{attrs:{"tips":"最多上传1张"},model:{value:(_vm.crudModel.coverImages),callback:function ($$v) {_vm.$set(_vm.crudModel, "coverImages", $$v)},expression:"crudModel.coverImages"}})],1),_c('template',{slot:"contentForm"},[_c('ZEditor',{model:{value:(_vm.crudModel.content),callback:function ($$v) {_vm.$set(_vm.crudModel, "content", $$v)},expression:"crudModel.content"}})],1),_c('template',{slot:"cityCodesForm"},[_c('el-select',{ref:"cityCodeRef",attrs:{"filterable":"","clearable":"","multiple":"","placeholder":"请选择"},on:{"change":_vm.changeCityCode},model:{value:(_vm.crudModel.cityCodes),callback:function ($$v) {_vm.$set(_vm.crudModel, "cityCodes", $$v)},expression:"crudModel.cityCodes"}},_vm._l((_vm.citys),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.name,"value":item.code,"disabled":item.disabled}})}),1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }