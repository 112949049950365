import request from '@/router/axios'

// 分页查询资讯列表
export function getInfoListApi(query) {
  return request({
    url: '/admin/seekAdviceFrom/page',
    method: 'get',
    params: query
  })
}
// 添加-修改资讯
export function postEditApi(obj) {
  return request({
    url: '/admin/seekAdviceFrom/addOrUpdate',
    method: 'post',
    data: obj
  })
}
// 修改排序
export function postUpdateRankApi(obj) {
  return request({
    url: `/admin/seekAdviceFrom/updateRank/${obj.id}/${obj.operateType}`,
    method: 'post'
  })
}
// 上下线
export function posUpdatePublishApi(obj) {
  return request({
    url: `/admin/seekAdviceFrom/publishOrUnpublish/${obj.id}/${obj.published}`,
    method: 'post'
  })
}
// 切换推荐
export function posUpdateRecApi(obj) {
  return request({
    url: `/admin/seekAdviceFrom/recommendOrUnrecommend/${obj.id}/${obj.recommended}`,
    method: 'post'
  })
}

