import { citys } from "@/util/location/index.js";
let cityList = JSON.parse(JSON.stringify(citys))
cityList.unshift({
  code: "",
  name: "全国",
  provinceCode: "-2",
})
export const tableOption = {
  border: true,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  align: 'center',
  searchMenuSpan: 6,
  highlightCurrentRow: true,
  menuWidth: 300,
  viewBtn: false,
  delBtn: false,
  tip: false,
  columnBtn: false,
  menu: true,
  searchLabelWidth: 100,
  labelWidth: 160,
  addBtn: true,
  addBtnText: '新增资讯',
  refreshBtn: true,
  editBtnText: '修改',
  column: [
    {
      type: 'input',
      label: '排序',
      prop: 'sequence',
      addDisplay: false,
      editDisplay: false,
      slot: true
    },
    {
      type: 'input',
      label: '资讯标题',
      prop: 'title',
      span: 16,
      labelWidth: 160,
      search: true,
      rules: [{
        required: true,
        message: "请输入资讯标题",
        trigger: "blur"
      }]
    },
    {
      type: 'select',
      label: '分类',
      span: 16,
      prop: 'categorys',
      labelWidth: 160,
      overHidden: true,
      multiple: true,
      dicData: [
        {
          label: "行业动态",
          value: "行业动态",
        },
        {
          label: "陵园解答",
          value: "陵园解答",
        },
        {
          label: "选墓攻略",
          value: "选墓攻略",
        },
        {
          label: "殡葬常识",
          value: "殡葬常识",
        },
        {
          label: "文化习俗",
          value: "文化习俗",
        },
      ],
      rules: [{
        required: true,
        message: "请选择分类",
        trigger: "change"
      }]

    },
    {
      type: 'select',
      label: '分类',
      prop: 'category',
      hide: true,
      search: true,
      addDisplay: false,
      editDisplay: false,
      dicData: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "行业动态",
          value: "行业动态",
        },
        {
          label: "陵园解答",
          value: "陵园解答",
        },
        {
          label: "选墓攻略",
          value: "选墓攻略",
        },
        {
          label: "殡葬常识",
          value: "殡葬常识",
        },
        {
          label: "文化习俗",
          value: "文化习俗",
        },
      ],
    },
    {
      type: 'input',
      label: '可见地区',
      prop: 'cityCodes',
      span: 16,
      rules: [{
        required: true,
        message: "请选择可见地区",
        trigger: "change"
      }]
    },
    {
      type: 'select',
      label: '可见地区',
      prop: 'cityCode',
      span: 16,
      hide: true,
      search: true,
      addDisplay: false,
      editDisplay: false,
      dicData: cityList,
      props: {
        label: 'name',
        value: 'code'
      },
    },
    {
      type: 'input',
      label: 'SEO标题',
      prop: 'seoTitle',
      span: 20,
      rules: [{
        required: true,
        message: "请输入SEO标题",
        trigger: "blur"
      }]
    },
    {
      type: 'input',
      label: 'SEO描述',
      prop: 'seoDesc',
      span: 20,
      rules: [{
        required: true,
        message: "请输入SEO描述",
        trigger: "blur"
      }]
    },
    {
      type: 'input',
      label: 'SEO关键词',
      prop: 'seoKeywords',
      span: 20,
      rules: [{
        required: true,
        message: "请输入SEO关键词",
        trigger: "blur"
      }]
    },
    {
      type: 'number',
      label: '基础阅读量',
      prop: 'baseReadAmount',
      controls: false,
      hide: true,
      precision: 0,
      span: 16,
      min: 0,
      rules: [{
        required: true,
        message: "请输入基础阅读量",
        trigger: "blur"
      }]
    },
    {
      type: 'select',
      label: '显示状态',
      prop: 'published',
      span: 16,
      labelWidth: 160,
      rules: [{
        required: true,
        message: "请选择显示状态",
        trigger: "change"
      }],
      dicData: [
        {
          label: '全部',
          value: ''
        },
        {
        label: '隐藏',
        value: 0
      }, {
        label: '显示',
        value: 1
      }],
      search: true,
      width: 150,

      addDisplay: false,
      editDisplay: false
    },
    {
      type: 'select',
      label: '热门推荐',
      prop: 'recommended',
      span: 16,
      width: 200,
      labelWidth: 160,
      rules: [{
        required: true,
        message: "请选择是否热门推荐",
        trigger: "change"
      }],
      dicData: [
        {
          label: '全部',
          value: ''
        },
        {
        label: '否',
        value: 0
      }, {
        label: '是',
        value: 1
      }],
      search: true,
      addDisplay: false,
      editDisplay: false
    },
    {
      type: 'input',
      label: '浏览量',
      prop: 'liulanliang',
      addDisplay: false,
      editDisplay: false
    },
    {
      type: 'input',
      label: '创建人',
      prop: 'createBy',
      addDisplay: false,
      editDisplay: false
    },
    {
      type: 'input',
      label: '创建时间',
      prop: 'createTime',
      addDisplay: false,
      editDisplay: false
    },
    {
      type: 'input',
      label: '封面图',
      prop: 'coverImages',
      span: 16,
      rules: [{
        required: true,
        message: "请上传封面图",
        trigger: "change"
      }]
    },
    {
      type: 'input',
      label: '摘要',
      prop: 'digest',
      span: 20,
      hide: true,
      rules: [{
        required: true,
        message: "请输入摘要",
        trigger: "blur"
      }]
    },
    {
      type: 'input',
      label: '资讯内容',
      prop: 'content',
      span: 24,
      hide: true,
      rules: [{
        required: true,
        message: "请输入资讯内容",
        trigger: "blur"
      }]
    },
  ]
}
